import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0,4,2,5,7,6];

export const dictionary = {
		"/": [~8],
		"/(cms)/clients": [14,[4]],
		"/(cms)/clients/add-client": [15,[4]],
		"/(cms)/employees": [~16,[4]],
		"/(cms)/employees/add-employee": [~17,[4]],
		"/(cms)/employees/import-employees": [18,[4]],
		"/(cms)/employees/import-employees/[id]": [~19,[4]],
		"/(auth)/forgot-password": [9,[2]],
		"/(cms)/groups": [20,[4]],
		"/(cms)/lessons": [21,[4]],
		"/(cms)/lessons/external-lessons": [22,[4]],
		"/(cms)/lessons/my-lessons": [23,[4]],
		"/(cms)/lessons/my-lessons/edit-lesson/[...rest]": [24,[4,5]],
		"/(auth)/login": [~10,[2]],
		"/(auth)/new-password": [~11,[2]],
		"/(blank)/qr-code/[id]": [~13,[3]],
		"/test": [37,[7]],
		"/test/avatar": [38,[7]],
		"/test/badges": [39,[7]],
		"/test/buttons": [40,[7]],
		"/test/charts": [41,[7]],
		"/test/checkbox": [42,[7]],
		"/test/chips": [43,[7]],
		"/test/columns-modal": [44,[7]],
		"/test/confirmation-modal": [45,[7]],
		"/test/datalist": [46,[7]],
		"/test/date-composite-range": [47,[7]],
		"/test/date-input": [48,[7]],
		"/test/date-range": [49,[7]],
		"/test/datetime-input": [50,[7]],
		"/test/dot": [51,[7]],
		"/test/email-input": [52,[7]],
		"/test/employee-modal": [53,[7]],
		"/test/export-modal": [54,[7]],
		"/test/filter-modal": [55,[7]],
		"/test/generic-modal": [56,[7]],
		"/test/grid": [57,[7]],
		"/test/history": [58,[7]],
		"/test/icons": [59,[7]],
		"/test/image-editor": [60,[7]],
		"/test/language-badge": [61,[7]],
		"/test/links": [62,[7]],
		"/test/loading": [63,[7]],
		"/test/master-lesson-modal": [64,[7]],
		"/test/media-tool-modal": [65,[7]],
		"/test/menus": [66,[7]],
		"/test/multi-input": [67,[7]],
		"/test/number-code-input": [68,[7]],
		"/test/number-input": [69,[7]],
		"/test/password-input": [70,[7]],
		"/test/pdf": [71,[7]],
		"/test/phone-input": [72,[7]],
		"/test/progress-bar": [73,[7]],
		"/test/publish-banner": [74,[7]],
		"/test/radio": [75,[7]],
		"/test/read-more": [76,[7]],
		"/test/reminder-modal": [77,[7]],
		"/test/search-input": [78,[7]],
		"/test/select-multi-select": [79,[7]],
		"/test/slider": [80,[7]],
		"/test/snackbars": [81,[7]],
		"/test/svelte-test": [82,[7]],
		"/test/switch": [83,[7]],
		"/test/table": [84,[7]],
		"/test/tabs": [85,[7]],
		"/test/text-area": [86,[7]],
		"/test/text-input": [87,[7]],
		"/test/upload": [88,[7]],
		"/test/video": [89,[7]],
		"/(cms)/trails": [25,[4]],
		"/(cms)/trails/add-trail": [~26,[4]],
		"/(cms)/translations": [27,[4]],
		"/(auth)/two-factor": [~12,[2]],
		"/(cms)/user/profile": [28,[4]],
		"/(cms)/user/reset-password": [29,[4]],
		"/(cms)/user/settings": [30,[4]],
		"/(cms)/user/settings/[tabId]": [31,[4]],
		"/(cms)/user/settings/[tabId]/[uuid]": [32,[4]],
		"/(cms)/user/team": [33,[4]],
		"/(cms)/visitors": [34,[4,6]],
		"/(cms)/visitors/add-visitor": [35,[4,6]],
		"/(cms)/visitors/qr-codes": [36,[4,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';